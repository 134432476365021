
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setIsAuthorized } from "reducers/ProtectedRouteSlice";
import { setAccessToken } from "reducers/ProtectedRouteSlice";
const ProtectedRoute = ({children}) => {
  const { isAuthenticated, error, isLoading, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const origin = window.location.origin;
  const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(origin);
  useEffect(() => {
    const getToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
              audience :`https://service_api`,
              scope : "openid profile email offline_access read:current_user update:current_user_metadata"
            });
            dispatch(setAccessToken(accessToken));


          
        } catch (err) {
            console.error("Error fetching access token:", err);
        }
    };

    if (isAuthenticated) {
      console.log("Is authorized : ", user?.is_authorized)
        dispatch(setIsAuthorized(user?.is_authorized || false));
        getToken();        
    }
}, [isAuthenticated, getAccessTokenSilently, dispatch, getIdTokenClaims, user?.is_authorized]);

  if(isInternalIP){
    return children;
  }

  if (isLoading) {
    return <div></div>;
  }
  if (window.location.pathname === "/verifyEmail") {
    return children; 
  }

  if(user && !user?.email_verified) {
    console.log("Present in email verified")
    return <Navigate to="/verifyEmail" />
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;


    const config = {
  "DB_HOST": "192.168.20.13",
  "DB_PORT": "5432",
  "DB_USER": "postgres",
  "DB_PASSWORD": "root",
  "DB_NAME": "equity_graph",
  "PROXY_EXTERNAL_URL": "https://ui-proxy.uat.phoenix.zentropylabs.com/",
  "PROXY_INTERNAL_URL": "http://192.168.20.12:3003/",
  "CORS_URL": "http://192.168.20.12:9000/",
  "REACT_APP_DEV_EXTERNAL_URL": "https://thor.intraday.uat.phoenix.zentropylabs.com/",
  "REACT_APP_DEV_INTERNAL_URL": "http://192.168.20.12:9190/",
  "GRAPH_NAME": "'equity_graph'",
  "CLIENT_ID": "b0ffyKeR2fLVGzAyRTmAASsKK2XkiyiF",
  "UI_URL": "https://uat.phoenix.zentropylabs.com/"
};
    export default config;
    
/*eslint-disable*/
import React, { useEffect } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { useRealtimeZenSecurities2 } from "../components/getAllSourcesSecurities.js";
import Sidebar3 from "../components/Sidebar/Sidebar3.js";
import { DemoNavbar } from "../components/Navbars/DemoNavbar.js";
import TradesFilter from "../components/TradesFilter/presentation/TradesFilter.jsx";
import { saveSecurityList } from "../reducers/BrokerSlice.js";
import { useDispatch, useSelector } from "react-redux";
import UnAuthorizedPage from "components/UnAuthorizedPage.js";


var ps;


function Dashboard(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useRealtimeZenSecurities2();
  const isAuthorized = useSelector(state => state.protectedRoute.isAuthorized);
  //loaded here.

  useEffect(()=>{
    data.then((data)=>{
      dispatch(saveSecurityList(data))
    })
  },[])

  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current);
  //     document.body.classList.toggle("perfect-scrollbar-on");
  //   }
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.body.classList.toggle("perfect-scrollbar-on");
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    if(mainPanel.current){
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    
  }, [location]);

  useEffect(() => {

    if (location.pathname === "/default/" || location.pathname === "/default") {
      const defaultRoutePath = `/default${routes[0].path}`;
      navigate(defaultRoutePath);
    }
}, [location.pathname, navigate]);


  const handleActiveClick = (color) => {
    setActiveColor(color);
  };

  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  
  return (
    <div className="flex font-inter h-[100vh]">
        <Sidebar3
          {...props}
          routes={routes}
          bgColor={backgroundColor}
          activeColor={activeColor}
        />
      
      { isAuthorized || location.pathname === "/default/dashboard"  ? 
        <div className="relative w-full" ref={mainPanel}>
        <DemoNavbar {...props} />
          <div className="content ml-4 mt-4 mr-4">
            { 
              (location.pathname==="/default/dashboard" || location.pathname==="/default/optionChain" || location.pathname==="/default/externalTrades")?null:
              <Row>
              <Col xs="auto">
                <Card>
                  <CardBody>
                      <TradesFilter/>
                  </CardBody>            
                </Card>
              </Col>

              </Row>
            } 
            <Routes>         
                {
                routes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    element={prop.component}
                    key={key}
                    exact
                  />
                );
                })}
            </Routes>
              
            
          </div>
          <Footer fluid />
        </div> :
        <UnAuthorizedPage/>
      }
    </div>
  );
}

export default Dashboard;
